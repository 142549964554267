import { PathsApiV1PublicSessionLogsUserHasLoggedInWithinGetParametersQueryPlatformType } from '@core/types'

export const useAppPrompt = () => {
    const dayjs = useDayjs()
    const { isLoggedIn } = useAuth()
    const { isMobile, isAndroid } = useDevice()
    const { client } = useUserAuth()
    const { isMobileApp } = useWebauthn()

    const hasSeenAppPrompt = useCookie<boolean>('hasSeenAppPrompt', {
        expires: dayjs.tz().add(30, 'days').toDate(),
        sameSite: 'none',
        secure: true,
    })

    const isEligibleForAppPrompt = computed(() =>
        !isMobileApp
        && isMobile
        && isLoggedIn.value
    )

    const shouldFetchHasLoggedInWithin = computed(() =>
        isEligibleForAppPrompt.value
        && isAndroid
        && !hasSeenAppPrompt.value
    )

    const showAppPrompt = computed(() =>
        isEligibleForAppPrompt.value
        && import.meta.client
        && shouldFetchHasLoggedInWithin.value
        && query.data.value?.data?.hasLoggedIn === false
    )

    const query = useQuery(async () => {
        const response = await client.GET('/api/v1/public/session-logs/user/has-logged-in-within', {
            params: {
                query: {
                    platformType: PathsApiV1PublicSessionLogsUserHasLoggedInWithinGetParametersQueryPlatformType.App,
                    withinDays: 30,
                },
            },
        })

        return response
    }, {
        key: 'userAuth:has-logged-in-within',
        queryCache: false,
        server: false,
        lazy: true,
        enabled: shouldFetchHasLoggedInWithin
    })

    return query.enrich({
        hasSeenAppPrompt,
        isEligibleForAppPrompt,
        showAppPrompt,
    })
}
